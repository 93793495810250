import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { builtInQaSuggestions } from 'shared/constants';

import { SkipButton } from '../SkipButton';

import SuggestionsButtons from './SuggestionsButtons';
import SuggestionsSelect from './SuggestionsSelect';

function Suggestions(props) {
  const { message, emitSendMessage } = props;

  const { multiSelect, suggestions: messageSuggestions, isSkippable } = message.options;

  const [selectedSuggestions, setSelectedSuggestions] = React.useState([]);

  const initialSuggestions = messageSuggestions.filter((item) => item !== 'Skip');
  const useSelectComponent = initialSuggestions.length >= 6;

  const submitButtonStyle = useMemo(
    () => ({
      paddingLeft: '20px',
      paddingRight: '20px',
      marginBottom: useSelectComponent ? '230px' : '0',
    }),
    [useSelectComponent],
  );

  const suggestions = useMemo(() => {
    if (!useSelectComponent) {
      return initialSuggestions;
    }

    const suggestionsToHide = builtInQaSuggestions.map((builtInQaSuggestion) =>
      translate(builtInQaSuggestion).toLowerCase(),
    );

    return initialSuggestions.filter((suggestion) => {
      const suggestionToCheck = suggestion.toLowerCase();
      return !suggestionsToHide.includes(suggestionToCheck);
    });
  }, [initialSuggestions, useSelectComponent]);

  const onSubmit = useCallback(() => {
    const answer = suggestions.filter((suggestion) => selectedSuggestions.includes(suggestion)).join(';');

    emitSendMessage(answer);
  }, [suggestions, selectedSuggestions, emitSendMessage]);

  const onSkip = useCallback(() => emitSendMessage(translate('Skip')), [emitSendMessage]);

  const SuggestionsComponent = useSelectComponent ? SuggestionsSelect : SuggestionsButtons;

  return (
    <div className="text-center suggestion-wrapper">
      <SuggestionsComponent
        suggestions={suggestions}
        emitSendMessage={emitSendMessage}
        selectedSuggestions={selectedSuggestions}
        setSelectedSuggestions={setSelectedSuggestions}
        multiSelect={multiSelect}
      />

      <div key="submit" className="text-center margin-top">
        {
          <button
            type="submit"
            style={submitButtonStyle}
            className="btn btn-sm btn-primary"
            disabled={!selectedSuggestions.length}
            onClick={onSubmit}
          >
            {translate('Submit')}
          </button>
        }

        {isSkippable && <SkipButton useSelectComponent={useSelectComponent} onSkip={onSkip} />}
      </div>
    </div>
  );
}

Suggestions.propTypes = {
  message: PropTypes.object.isRequired,
  emitSendMessage: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default Suggestions;
