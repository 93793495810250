import React, { useMemo } from 'react';

type SkipButtonType = {
  useSelectComponent: boolean;
  onSkip: () => void;
};

export const SkipButton = ({ useSelectComponent, onSkip }: SkipButtonType) => {
  const skipButtonStyle = useMemo(
    () => ({
      minWidth: 84,
      maxHeight: 36,
      border: '1px solid #DEE7EB',
      marginBottom: useSelectComponent ? '230px' : '0',
    }),
    [useSelectComponent],
  );

  return (
    <button type="button" style={skipButtonStyle} className="btn btn-sm btn-light margin-left" onClick={onSkip}>
      {translate('Skip')}
    </button>
  );
};
