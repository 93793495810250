import React from 'react';

import { stringifyError } from '@eva/emf/app/shared/functions';
import { getMessageOptions, logError } from 'shared/functions';

import Suggestions from '../Suggestions';
import { SkipButton } from '../SkipButton';

/** @deprecated TODO remove in DEV-25601 skillsSet logic */
function CustomMessage(props) {
  const { message, emitSendMessage, isLastMessage } = props;

  const [CustomMessageComponent, setCustomMessageComponent] = React.useState(null);
  const [error, setError] = React.useState(null);

  /** @deprecated TODO remove in DEV-25601 skillsSet logic */
  const messageOptions = getMessageOptions(message);

  /** @deprecated TODO remove in DEV-25601 skillsSet logic */
  React.useLayoutEffect(() => {
    if (messageOptions.customMessage) {
      if (messageOptions.customFrontend === 'eucf') {
        /** @deprecated TODO remove in DEV-25601 skillsSet logic */
        import('@eva/eucf/app/containers/candidateMessages').then(
          (candidateMessages) => {
            const MessageComponent = candidateMessages.default[messageOptions.customMessage];
            if (MessageComponent) {
              setCustomMessageComponent(() => MessageComponent);
            } else {
              logError(`Requested custom form [${messageOptions.customMessage}] was not found`);
            }
          },
          (err) => setError(stringifyError(err)),
        );
      } else {
        setError(`Custom frontend ${messageOptions.customFrontend} not found`);
      }
    }
  }, [messageOptions.customFrontend, messageOptions.customMessage]);

  if (!isLastMessage) {
    return null;
  }

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  if (CustomMessageComponent) {
    return <CustomMessageComponent message={message} emitSendMessage={emitSendMessage} isLastMessage={isLastMessage} />;
  }

  return (
    <>
      {messageOptions.suggestions && <Suggestions message={message} emitSendMessage={emitSendMessage} />}
      {!messageOptions.suggestions && message.options?.isSkippable && (
        <div className="text-center suggestion-wrapper">
          <SkipButton useSelectComponent={false} onSkip={() => emitSendMessage(translate('Skip'))} />
        </div>
      )}
    </>
  );
}

// eslint-disable-next-line import/no-default-export
export default CustomMessage;
